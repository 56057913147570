import { gql } from '@apollo/react-hooks'

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $companyName: String!
    $companyAddress: String!
    $companyContact: String!
    $companyEmail: String!
    $companyAdminEmail: String!
    $companyContactFullName: String!
    $file: Upload!
  ) {
    company {
      createCompany(
        company: {
          companyName: $companyName
          companyAddress: $companyAddress
          companyContact: $companyContact
          companyEmail: $companyEmail
          companyAdminEmail: $companyAdminEmail
          companyContactFullName: $companyContactFullName
          file: $file
        }
      ) {
        company {
          status
          companyEmail
          companyName
          companyContactFullName
          id
          status
        }
        error
      }
    }
  }
`

export const COMPANY_VERIFICATION = gql`
  mutation CompanyVerification(
    $companyId: String!
    $status: CompanyVerificationStatus
  ) {
    company {
      companyVerification(
        companyData: { companyId: $companyId, status: $status }
      ) {
        success
        status
        error
      }
    }
  }
`

export const LOGIN = gql`
  mutation SignIn($email: String!, $password: String!) {
    auth {
      signIn(signIn: { email: $email, password: $password }) {
        error
        user {
          id
          token
          updatedAt
          companyRole
          profileCompleted
          level
          email
          isFirstLogin
          firstName
          lastName
          oAuthToken {
            accessToken
            realmId
            refreshToken
            email
          }
          company {
            id
            companyName
          }
          role {
            projectId {
              id
            }
            role
          }
        }
      }
    }
  }
`

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $companyId: ID!
    $projectName: String!
    $network: String!
    $projectAdminEmail: String!
    $budget: Float!
    $file: Upload
  ) {
    project {
      createProject(
        projectData: {
          budget: $budget
          companyId: $companyId
          network: $network
          projectAdminEmail: $projectAdminEmail
          projectName: $projectName
          file: $file
        }
      ) {
        error
        project {
          id
          budget
          network
          projectAdminEmail
          projectName
        }
      }
    }
  }
`
export const ADD_USER = gql`
  mutation AddUser(
    $firstName: String!
    $lastName: String
    $email: String!
    $projectId: String!
    $role: InviteUserRole!
    $picUpload: Upload!
    $signature: Upload!
    $department: String!
    $hod: Boolean!
    $departmentApprover: Boolean
  ) {
    auth {
      addUser(
        userData: {
          email: $email
          role: $role
          projectId: $projectId
          firstName: $firstName
          lastName: $lastName
          picUpload: $picUpload
          signature: $signature
          department: $department
          hod: $hod
          departmentApprover: $departmentApprover
        }
      ) {
        error
        user {
          firstName
          email
          id
        }
      }
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ResetPassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    auth {
      resetPassword(
        resetPasswordData: {
          oldPassword: $oldPassword
          newPassword: $newPassword
          confirmPassword: $confirmPassword
        }
      ) {
        error
        message
        success
      }
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($companyId: String!) {
    company {
      deleteCompany(companyId: $companyId) {
        error
        message
        success
      }
    }
  }
`
export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: String!) {
    project {
      deleteProject(projectId: $projectId) {
        error
        message
        success
      }
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    auth {
      deleteUser(userId: $userId) {
        error
        message
        success
      }
    }
  }
`
export const EDIT_COMPANY = gql`
  mutation EditCompany(
    $companyId: String!
    $companyName: String
    $companyContact: String
    $companyAddress: String
    $companyContactFullName: String
    $file: Upload
  ) {
    company {
      editCompany(
        companyData: {
          companyId: $companyId
          companyName: $companyName
          companyContact: $companyContact
          companyAddress: $companyAddress
          companyContactFullName: $companyContactFullName
          file: $file
        }
      ) {
        company {
          id
          companyEmail
          status
          companyName
          companyContact
          companyContactFullName
        }
        error
      }
    }
  }
`
export const EDIT_USER_PROFILE = gql`
  mutation EditUserProfile(
    $userId: String!
    $firstName: String
    $lastName: String
    $signature: Upload
    $picUpload: Upload
    $role: InviteUserRole
    $hod: Boolean!
    $department: String
  ) {
    auth {
      editUserProfile(
        editUserData: {
          userId: $userId
          firstName: $firstName
          lastName: $lastName
          signature: $signature
          picUpload: $picUpload
          role: $role
          hod: $hod
          department: $department
        }
      ) {
        error
        user {
          email
          firstName
          id
          lastName
          companyRole
        }
      }
    }
  }
`
export const EDIT_USER_OWN_PROFILE = gql`
  mutation EditUserProfile(
    $userId: String!
    $firstName: String
    $lastName: String
    $signature: Upload
    $picUpload: Upload
    $role: InviteUserRole
    $openAiKey: String
  ) {
    auth {
      editUserProfile(
        editUserData: {
          userId: $userId
          firstName: $firstName
          lastName: $lastName
          signature: $signature
          picUpload: $picUpload
          role: $role
          openAiKey: $openAiKey
        }
      ) {
        error
        user {
          email
          firstName
          id
          lastName
          companyRole
          openAiKey
        }
      }
    }
  }
`
export const EDIT_USER_PROFILE1 = gql`
  mutation Mutation(
    $projectId: String!
    $firstName: String
    $lastName: String
    $role: InviteUserRole
    $companyRole: InviteUserRole
    $userId: String!
    $hod: Boolean!
    $departmentApprover: Boolean
    $department: String
  ) {
    project {
      editProjectUser(
        editProjectUser: {
          userId: $userId
          firstName: $firstName
          lastName: $lastName
          projectDetail: {
            projectId: $projectId
            role: $role
            departmentApprover: $departmentApprover
          }
          companyRole: $companyRole
          hod: $hod
          department: $department
        }
      ) {
        user {
          email
          firstName
          lastName
          id
          companyRole
          role {
            role
          }
        }
        error
      }
    }
  }
`
export const EDIT_PROJECT = gql`
  mutation EditProjectDetail(
    $projectId: ID!
    $actualFinalCost: Float
    $budget: Float
    $network: String
    $totalDays: Int
    $projectAdminEmail: String
    $projectName: String
    $totalDevelopmentDays: Int
  ) {
    project {
      editProjectDetail(
        editProject: {
          projectId: $projectId
          actualFinalCost: $actualFinalCost
          budget: $budget
          network: $network
          totalDays: $totalDays
          projectAdminEmail: $projectAdminEmail
          projectName: $projectName
          totalDevelopmentDays: $totalDevelopmentDays
        }
      ) {
        error
        project {
          id
          projectName
          projectAdminEmail
          network
          budget
        }
      }
    }
  }
`
export const ADD_COST_REPORT = gql`
  mutation (
    $companyId: ID!
    $projectId: ID!
    $costReportDate: String!
    $additional_notes: String!
    $file: Upload!
  ) {
    costReport {
      createCostReport(
        costReportData: {
          companyId: $companyId
          projectId: $projectId
          costReportDate: $costReportDate
          file: $file
          additional_notes: $additional_notes
        }
      ) {
        costReport {
          createdAt
          isDeleted
          id
        }
        error
      }
    }
  }
`
export const UPDATE_COST_REPORT = gql`
  mutation UpdateCostReport(
    $costReportId: ID!
    $file: Upload!
    $costReportDate: String!
    $additional_notes: String!
  ) {
    costReport {
      updateCostReport(
        costReportData: {
          costReportId: $costReportId
          file: $file
          costReportDate: $costReportDate
          additional_notes: $additional_notes
        }
      ) {
        error
        costReport {
          id
          costReportDate
        }
      }
    }
  }
`

export const DELETE_COST_REPORT = gql`
  mutation DeleteCostReport($costReportId: String!) {
    costReport {
      deleteCostReport(costReportId: $costReportId) {
        error
        message
        success
      }
    }
  }
`

export const ADD_COMPANY_ADMIN = gql`
  mutation AddCompanyAdmin(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    auth {
      addCompanyAdmin(
        userData: { email: $email, firstName: $firstName, lastName: $lastName }
      ) {
        user {
          firstName
          id
          email
        }
        error
      }
    }
  }
`
export const ADD_CHART_ACCOUNT = gql`
  mutation CreateChartOfAccounts($companyId: ID!, $file: Upload!) {
    costReport {
      createChartOfAccounts(
        chartOfAccountsData: { companyId: $companyId, file: $file }
      ) {
        chartOfAccounts {
          id
          accountCodes {
            accountId
            accountName
          }
        }
        error
      }
    }
  }
`

export const DELETE_PROJECT_USER = gql`
  mutation DeleteProjectUser($userId: String!, $projectId: String!) {
    project {
      deleteProjectUser(
        deleteUserData: { userId: $userId, projectId: $projectId }
      ) {
        error
        success
        message
      }
    }
  }
`

export const COST_REPORT_FORMAT = gql`
  mutation ($companyId: ID!, $projectId: ID!, $file: Upload!) {
    costReport {
      uploadCostReportFormat(
        costReportData: {
          companyId: $companyId
          projectId: $projectId
          file: $file
        }
      ) {
        message
        success
        error
      }
    }
  }
`

export const ADD_APPROVAL_MATRIX = gql`
  mutation ApprovalMatrix(
    $companyId: String!
    $projectId: String!
    $approverId: String!
    $isApprover: Boolean!
    $maxAmount: Float!
    $approvalRange: JSON!
  ) {
    approvalMatrix {
      createApprover(
        approverData: {
          companyId: $companyId
          projectId: $projectId
          approverId: $approverId
          isApprover: $isApprover
          maxAmount: $maxAmount
          approvalRange: $approvalRange
        }
      ) {
        error
        approvalMatrix {
          approvalRange
          companyId
          mainApproverId
          mainReviewerId
          projectAdminId
          projectId
          id
          chartOfAccountRange {
            end
            start
          }
          isDeleted
        }
      }
    }
  }
`

export const REMOVE_APPROVAL_MATRIX = gql`
  mutation deleteApprover($companyId: ID!, $projectId: ID!, $approverId: ID!) {
    approvalMatrix {
      deleteApprover(
        approverData: {
          companyId: $companyId
          projectId: $projectId
          approverId: $approverId
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const FORGOT_PASSWORD = gql`
  mutation NewPassword($password: String!) {
    auth {
      newPassword(newPassword: { password: $password }) {
        error
        message
        success
      }
    }
  }
`
export const COMPANY_PROFILE = gql`
  mutation UpdateCompanyProfile(
    $companyId: String!
    $companyAddress: String
    $contractUpload: Upload!
    $file: Upload!
  ) {
    company {
      editCompany(
        companyData: {
          companyId: $companyId
          companyAddress: $companyAddress
          contractUpload: $contractUpload
          file: $file
        }
      ) {
        error
        company {
          id
          companyEmail
          file
        }
      }
    }
  }
`

export const UPDATE_PROJECT_DETAILS = gql`
  mutation projectDetails(
    $projectId: ID!
    $projectName: String
    $network: String
    $budget: Float
    $efc: Float
    $projectApprovedOverUnder: Float
    $projectFundingToDate: Float
    $projectInvoicedToDate: Float
    $projectNetworkProductionEmail: String
    $projectNetworkFinanceEmail: String
    $projectBankBalancePerBook: Float
    $projectBankBalancePerBank: Float
    $projectOutStandingChecks: Float
    $projectNetworkAuditComplete: Boolean
    $projectResidualsTalent: Float
    $projectResidualsOther: Float
    $projectBonusDue: Float
    $autoFillSubmission: Boolean
    $projectLegalEntityName: String
    $projectAdminEmail: String
    $projectBankAccountContactEmail: String
    $insuranceClaim: Float
    $primaryLocation: String
    $ProjectPayrollContactEmail: String
    $anticipatedIncentive: Float
    $episodeTypes: [String]
    $qualifiedExpenseTypes: [String]
    $setTypes: [String]
    $audit: Boolean
    $emailNotification: Boolean
  ) {
    project {
      editProjectDetail(
        editProject: {
          projectId: $projectId
          projectName: $projectName
          network: $network
          budget: $budget
          efc: $efc
          projectApprovedOverUnder: $projectApprovedOverUnder
          projectFundingToDate: $projectFundingToDate
          projectInvoicedToDate: $projectInvoicedToDate
          projectNetworkProductionEmail: $projectNetworkProductionEmail
          projectNetworkFinanceEmail: $projectNetworkFinanceEmail
          projectAdminEmail: $projectAdminEmail
          projectBankAccountContactEmail: $projectBankAccountContactEmail
          projectBankBalancePerBook: $projectBankBalancePerBook
          projectBankBalancePerBank: $projectBankBalancePerBank
          projectOutStandingChecks: $projectOutStandingChecks
          projectNetworkAuditComplete: $projectNetworkAuditComplete
          projectResidualsTalent: $projectResidualsTalent
          projectResidualsOther: $projectResidualsOther
          projectBonusDue: $projectBonusDue
          autoFillSubmission: $autoFillSubmission
          projectLegalEntityName: $projectLegalEntityName
          insuranceClaim: $insuranceClaim
          primaryLocation: $primaryLocation
          anticipatedIncentive: $anticipatedIncentive
          ProjectPayrollContactEmail: $ProjectPayrollContactEmail
          episodeTypes: $episodeTypes
          qualifiedExpenseTypes: $qualifiedExpenseTypes
          setTypes: $setTypes
          audit: $audit
          emailNotification: $emailNotification
        }
      ) {
        error
        project {
          id
          autoFillSubmission
          projectLegalEntityName
          projectName
          network
          budget
          efc
          projectApprovedOverUnder
          projectFundingToDate
          projectInvoicedToDate
          insuranceClaim
          projectAdminEmail
          projectNetworkProductionEmail
          projectNetworkFinanceEmail
          projectBankAccountContactEmail
          projectBankBalancePerBank
          projectBankBalancePerBook
          projectOutStandingChecks
          projectNetworkAuditComplete
          projectResidualsTalent
          projectResidualsOther
          projectBonusDue
          primaryLocation
          anticipatedIncentive
          ProjectPayrollContactEmail
          qualifiedExpenseTypes
          episodeTypes
          setTypes
          audit
          emailNotification
        }
      }
    }
  }
`

export const LUCA_EXPORT = gql`
  mutation CreateProjectExport(
    $companyId: String!
    $projectId: String!
    $exportType: ProjectExportType!
    $managementType: ProjectManagementType!
  ) {
    project {
      createProjectExport(
        projectInput: {
          companyId: $companyId
          projectId: $projectId
          exportType: $exportType
          managementType: $managementType
        }
      ) {
        error
        message
        success
      }
    }
  }
`

export const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $senderId: ID!
    $mentionId: [ID]!
    $text: String!
    $submissionType: RequestType!
    $submissionId: ID!
    $projectId: ID!
  ) {
    messages {
      createMessage(
        messageInput: {
          senderId: $senderId
          mentionId: $mentionId
          text: $text
          submissionType: $submissionType
          submissionId: $submissionId
          projectId: $projectId
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const EDIT_MESSAGE = gql`
  mutation EditMessage($messageId: ID!, $mentionId: [ID]!, $text: String!) {
    messages {
      editMessage(
        messageInput: {
          messageId: $messageId
          mentionId: $mentionId
          text: $text
        }
      ) {
        error
        success
        message
      }
    }
  }
`
export const DELETE_COMMENTS = gql`
  mutation DeleteMessage($messageId: String!) {
    messages {
      deleteMessage(messageId: $messageId) {
        success
        message
        error
      }
    }
  }
`

export const CHANGE_EMAIL_NOTIFICATION = gql`
  mutation EmailNotification($notificationInput: EmailNotificationInput) {
    messages {
      emailNotification(notificationInput: $notificationInput) {
        success
        message
        error
      }
    }
  }
`
export const LOG_OUT_QUICK_BOOK_ACCOUNT = gql`
  mutation LogOutQBAccount($userId: String!) {
    auth {
      logOutQBAccount(userId: $userId) {
        success
        message
        error
      }
    }
  }
`

export const UPLOAD_COST_REPORT_BUDGET = gql`
  mutation CostReportBudget($projectId: ID!, $file: Upload!) {
    costReport {
      generateCostReport(reportData: { projectId: $projectId, file: $file }) {
        data {
          BUDGET
          BUDGETACCT
          CURRENTCOSTS
          DESCRIPTION
          ESTIMATETOCOMPLETE
          PURCHASEORDERS
          TOTALCOSTTODATE
          TOTALESTIMATEFINALCOST

          VARIANCE
        }
        error
        url
      }
    }
  }
`

export const OPEN_AI_CHAT = gql`
  mutation OpenAiChartGPT($search: String!) {
    openAi {
      sendMessage(search: $search) {
        error
        result
      }
    }
  }
`

export const CREATE_COST_REPORT_CONFIGURATION = gql`
  mutation CreateBudgetConfigInput(
    $createBudgetConfigInput: CreateCostReportConfigInput!
  ) {
    costReport {
      createBudgetConfigInput(
        createBudgetConfigInput: $createBudgetConfigInput
      ) {
        error
        message
        statusCode
      }
    }
  }
`

export const EXPORT_COST_REPORT_RECORDS = gql`
  mutation ExportCostReport($emailCostReportInput: emailCostReportInput!) {
    costReport {
      emailCostReport(emailCostReportInput: $emailCostReportInput) {
        message
        status
      }
    }
  }
`

export const REFRESH_COST_REPORT_DATA = gql`
  mutation RefreshCostReportData(
    $refreshCostReportInput: RefreshCostReportInput!
  ) {
    costReport {
      refreshCostReportData(RefreshCostReportInput: $refreshCostReportInput) {
        message
        status
      }
    }
  }
`
export const DOWNLOAD_COST_REPORT_EXCEL = gql`
  mutation DownloadCostReportDetails(
    $excelExportCostReportInput: ExcelExportCostReportInput!
  ) {
    costReport {
      exportExcelCostReport(
        ExcelExportCostReportInput: $excelExportCostReportInput
      ) {
        file
        message
        status
      }
    }
  }
`

export const UPDATE_COST_REPORT_NOTE = gql`
  mutation UpdateCostReportNote(
    $companyId: String!
    $projectId: String!
    $userId: String!
    $costReportMappedCustomer: String!
    $notes: String!
  ) {
    costReport {
      updateCostReportNote(
        UpdateCostReportNoteInput: {
          companyId: $companyId
          projectId: $projectId
          userId: $userId
          costReportMappedCustomer: $costReportMappedCustomer
          notes: $notes
        }
      ) {
        success
        message
        error
      }
    }
  }
`

export const CREATE_FILTER_TEMPLATE = gql`
  mutation createFilterTemplate(
    $filters: [String]!
    $name: String!
    $filterType: ReportBuilderType!
  ) {
    approvalRequest {
      createFilterTemplate(
        FilterTemplateInput: {
          filters: $filters
          name: $name
          filterType: $filterType
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const DELETE_FILTER_TEMPLATE = gql`
  mutation deleteFilterTemplate($templateId: String!) {
    approvalRequest {
      deleteFilterTemplate(FilterTemplateInput: { templateId: $templateId }) {
        error
        message
        success
      }
    }
  }
`
